<template>
    <div class="container">
        <!-- <v-row class="">
      <v-col cols="12" class="top-panel px-0 py-0 ml-3">
        <div class="arrow-btn">
          <img
            src="../assets/img/arrowBack.svg"
            class="arrow-img"
            alt="arrow-back"
          />
        </div>
      </v-col>
    </v-row> -->

        <div id="accounts">
            <div class="tabs">
                <div class="">
                    <v-toolbar flat id="custom-toolbar" style="height: 100%;">
                        <div class="toolbar-container">
                            <div class="title-popular-container">
                                <v-toolbar-title class="title">Популярные блюда
                                </v-toolbar-title>
                            </div>
                            <div class="toolbar-buttons-container">
                                <div class="col-4 restaurant-input">
                                    <div class="">
                                        <v-autocomplete v-model="selectedRestaurant" :items="restaurantsList"
                                            item-text="restaurant.name" item-value="restaurantId" outlined
                                            label="Выберите ресторан" hide-details solo clearable id="restaurant-selector"
                                            placeholder="Выберите ресторан" class="custom-scroll"
                                            @change="onRestaurantChange">
                                            <template v-slot:item="{ item, isSelected }">
                                                <div :class="['restaurant-item', { active: isSelected }]">
                                                    {{ item.restaurant.name }} {{ item.restaurant.address ?
                                                        `(${item.restaurant.address})` : '' }}
                                                </div>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </div>
                                <div class="buttons-container">
                                    <div class="date-picker">
                                        <v-text-field v-model="formattedDateRangeProd"
                                            :label="`Период сравнения: ${formattedCompareDataRangeProd}`" readonly outlined
                                            @click="dialog = true" сlass="custom-text-field" hide-details="auto">
                                            <!-- Иконка календаря слева -->
                                            <template v-slot:prepend-inner>
                                                <v-icon сlass="mt-0">mdi-calendar</v-icon>
                                            </template>
                                        </v-text-field>

                                        <!-- Диалог с календарем -->
                                        <v-dialog v-model="dialog" max-width="290">
                                            <v-card id="date-picker-body">
                                                <v-card-actions class="d-flex justify-center">
                                                    <v-btn :class="['btn-exel-callendar', 'mx-2']" :color="dateType === 'month' ? 'primary' : 'grey darken-1'
                                                        " @click="setDateType('month')">
                                                        Месяц
                                                    </v-btn>
                                                    <v-btn :class="['btn-exel-callendar', 'mx-2']" :color="dateType === 'date' ? 'primary' : 'grey darken-1'
                                                        " @click="setDateType('date')">
                                                        Неделя
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-date-picker v-model="selectedDate" @input="onDateChange" scrollable
                                                    :first-day-of-week="1" :allowed-dates="allowedDates" :type="dateType">
                                                </v-date-picker>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <button class="btn-exel" @click="getCompareOrders()">
                                                        Применить
                                                    </button>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                    <div class="btn-container">
                                        <!-- <button class="btn-exel" @click="toggleDateType">
                                      {{
                                      dateType === "month"
                                          ? "Недельное сравнение"
                                          : "Месячное сравнение"
                                  }}
                                  </button> -->
                                        <button @click="downloadData" class="btn-exel">
                                            <vue-excel-xlsx class="btn-download" ref="excelBtn" :data="formattedData"
                                                :columns="columns" :file-name="getExcelFileName()" :file-type="'xlsx'"
                                                :sheet-name="'Популярные блюда'" title="Выгрузить в Excel">
                                                Выгрузить <img src="../assets/img/exel-btn.svg" alt="" />
                                            </vue-excel-xlsx>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-toolbar>
                </div>
            </div>
            <div v-if="noDataMessage" class="no-data-message">
                <div class="error-container d-flex align-center justify-center">
                    <div class="chart-heading">
                        <p class="error-head">{{ noDataMessage }}</p>
                        <p class="error-heading">
                            За выбранный период
                            <b>{{ this.formattedDateRangeProd }} </b> отсуствуют данные.
                        </p>

                        <p class="error-heading">
                            В данный промежуток времени ресторан еще не работал
                        </p>
                    </div>
                </div>
            </div>
            <div v-else-if="isLoading" class="loading-message d-flex align-center justify-center">
                <v-progress-circular indeterminate size="40" color="primary"></v-progress-circular>
            </div>
            <div v-else>
                <div class="block-container">
                    <div class="bar-chart-block"
                        v-if="dashboardInfo.topFiveGlobal && !dashboardInfo.topFiveGlobal.every(item => item === null)">
                        <h2 class="doughnut-name mb-1">Топ 5</h2>
                        <div class="stat-item" @click="getProduct(product)" v-for="(product, index) in dashboardInfo.topFiveGlobal.filter(
                            (product) => product !== null
                        )" :key="index">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="stat-title" v-bind="attrs" v-on="on">
                                        {{ product.productName }}
                                    </span>
                                </template>
                                <span>
                                    {{ product.productName }} — {{ product.totalAmount.toLocaleString() }} ₸
                                </span>
                            </v-tooltip>
                            <div class="stat-bars">
                                <div class="stat-bar current">
                                    <div class="bar" :style="{
                                        width:
                                            calculateBarWidth(
                                                product.totalAmount,
                                                dashboardInfo.topFiveGlobal
                                            ) + '%',
                                    }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmount / 1000).toFixed(1).replace(/\.0$/, "")
                                    }}</span>
                                </div>
                                <div class="stat-bar previous">
                                    <div class="bar" :style="{
                                                width:
                                                    calculateBarWidth(
                                                        product.totalAmountOld,
                                                        dashboardInfo.topFiveGlobal
                                                    ) + '%',
                                            }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmountOld / 1000)
                                            .toFixed(1)
                                            .replace(/\.0$/, "")
                                    }}</span>
                                </div>
                            </div>
                            <div class="percent-hg" style="flex: 3">
                                <span class="percent-status" v-if="product.changePercentage === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ product.changePercentage }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="product.changePercentage > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ product.changePercentage.toFixed() }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="product.changePercentage < 0">
                                    <span class="percent-text-red">{{ product.changePercentage.toFixed() }}%</span>
                                    <span class="arrow-color-bot" v-if="product.changePercentage < 0">▼</span>
                                </span>
                            </div>
                        </div>
                        <div class="bottom-container">
                            <span class="bottom-text-top">в тысячах тенге</span>
                        </div>
                    </div>
                    <div class="bar-chart-block"
                        v-if="dashboardInfo.topFiveInHouse && !dashboardInfo.topFiveInHouse.every(item => item === null)">
                        <h2 class="doughnut-name mb-1">Топ 5 в зале</h2>
                        <div class="stat-item" @click="getProduct(product)" v-for="(product, index) in dashboardInfo.topFiveInHouse.filter(
                            (product) => product !== null
                        )" :key="index">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="stat-title" v-bind="attrs" v-on="on">
                                        {{ product.productName }}
                                    </span>
                                </template>
                                <span>
                                    {{ product.productName }} — {{ product.totalAmount.toLocaleString() }} ₸
                                </span>
                            </v-tooltip>
                            <div class="stat-bars">
                                <div class="stat-bar current">
                                    <div class="bar-in-house" :style="{
                                        width:
                                            calculateBarWidth(
                                                product.totalAmount,
                                                dashboardInfo.topFiveInHouse
                                            ) + '%',
                                    }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmount / 1000).toFixed(1).replace(/\.0$/, "")
                                    }}</span>
                                </div>
                                <div class="stat-bar previous">
                                    <div class="bar-in-house" :style="{
                                                width:
                                                    calculateBarWidth(
                                                        product.totalAmountOld,
                                                        dashboardInfo.topFiveInHouse
                                                    ) + '%',
                                            }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmountOld / 1000)
                                            .toFixed(1)
                                            .replace(/\.0$/, "")
                                    }}</span>
                                </div>
                            </div>
                            <div class="percent-hg" style="flex: 3; width: 20%">
                                <span class="percent-status" v-if="product.changePercentage === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ product.changePercentage }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="product.changePercentage > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ product.changePercentage.toFixed() }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="product.changePercentage < 0">
                                    <span class="percent-text-red">{{ product.changePercentage.toFixed() }}%</span>
                                    <span class="arrow-color-bot" v-if="product.changePercentage < 0">▼</span>
                                </span>
                            </div>
                        </div>
                        <div class="bottom-container">
                            <span class="bottom-text-top">в тысячах тенге</span>
                        </div>
                    </div>
                    <div class="bar-chart-block"
                        v-if="dashboardInfo.topFiveDelivery && !dashboardInfo.topFiveDelivery.every(item => item === null)">
                        <h2 class="doughnut-name mb-1">Топ 5 на доставку и навынос</h2>
                        <div class="stat-item" @click="getProduct(product)" v-for="(product, index) in dashboardInfo.topFiveDelivery.filter(
                            (product) => product !== null
                        )" :key="index">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span class="stat-title" v-bind="attrs" v-on="on">
                                        {{ product.productName }}
                                    </span>
                                </template>
                                <span>
                                    {{ product.productName }} — {{ product.totalAmount.toLocaleString() }} ₸
                                </span>
                            </v-tooltip>
                            <div class="stat-bars">
                                <div class="stat-bar current">
                                    <div class="bar-delivery" :style="{
                                        width:
                                            calculateBarWidth(
                                                product.totalAmount,
                                                dashboardInfo.topFiveDelivery
                                            ) + '%',
                                    }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmount / 1000).toFixed(1).replace(
                                            /\.0$/,

                                            ""
                                        )
                                    }}</span>
                                </div>
                                <div class="stat-bar previous">
                                    <div class="bar-delivery" :style="{
                                                width:
                                                    calculateBarWidth(
                                                        product.totalAmountOld,
                                                        dashboardInfo.topFiveDelivery
                                                    ) + '%',
                                            }"></div>
                                    <span class="bar-amount">{{
                                        (product.totalAmountOld / 1000)
                                            .toFixed(1)
                                            .replace(/\.0$/, "")
                                    }}</span>
                                </div>
                            </div>
                            <div class="percent-hg" style="flex: 3; width: 20%">
                                <span class="percent-status" v-if="product.changePercentage === 0">
                                    <span class="neutral-arrows">▲</span>
                                    <span class="percent-text-gray">{{ product.changePercentage }}%</span>
                                    <span class="neutral-arrows">▼</span>
                                </span>
                                <span class="percent-status-green" v-if="product.changePercentage > 0">
                                    <span class="arrow-color-top">▲</span>
                                    <span class="percent-text-green">{{ product.changePercentage.toFixed() }}%</span>
                                </span>
                                <span class="percent-status percent-padding" v-if="product.changePercentage < 0">
                                    <span class="percent-text-red">{{ product.changePercentage.toFixed() }}%</span>
                                    <span class="arrow-color-bot" v-if="product.changePercentage < 0">▼</span>
                                </span>
                            </div>
                        </div>
                        <div class="bottom-container">
                            <span class="bottom-text-top">в тысячах тенге</span>
                        </div>
                    </div>
                    <div class="doughnut-border">
                        <h2 class="doughnut-name mb-1">Позиции Меню</h2>
                        <DoughnutOrders :dashboardData="dashboardInfo" />
                    </div>
                </div>
                <v-container v-if="orderData" class="secondary mt-10 table-container">
                    <v-row>
                        <v-col>
                            <v-data-table class="guest-grid" :headers="headers" :items="filteredGuests" :search="search"
                                :footer-props="footerProps" :options.sync="options" :server-items-length="totalItems"
                                @update:options="getProductsList" hide-default-footer :mobile-breakpoint="0">
                                <template v-slot:top>

                                    <v-toolbar flat>
                                        <div class="d-flex search-buttons-container">
                                            <div class="search-component">
                                                <input type="search" class="search-component__input mr-10" v-model="search"
                                                    append-icon="mdi-magnify" placeholder="Поиск по названию блюда"
                                                    single-line hide-details />
                                                <svg width="17" height="17" class="search-component__icon">
                                                    <use href="../assets/icons/icons.svg#search-icon"></use>
                                                </svg>
                                            </div>
                                            <div class="search-component">
                                                <v-select class="custom-scroll-2 search-component__input-filter"
                                                    v-model="selectedProduct" :items="itemsProduct"
                                                    @change="handleProduct" />
                                            </div>
                                        </div>
                                        <v-dialog v-if="product" v-model="productDialog" max-width="820">
                                            <v-card class="modal-card" max-width="820">
                                                <div class="modal-header">
                                                    <h3 class="modal-title">{{ product.productName }}</h3>
                                                    <v-btn @click="closeProductDialog()"
                                                        icon><v-icon>mdi-close</v-icon></v-btn>
                                                </div>
                                                <div v-if="isLoadingGuest" class="
                                                        loading-message
                                                        d-flex
                                                        align-center
                                                        justify-center
                                                    ">
                                                    <v-progress-circular indeterminate size="40"
                                                        color="primary"></v-progress-circular>
                                                </div>
                                                <div v-else class="modal-container">
                                                    <div class="third-container">
                                                        <div class="card-item-3">
                                                            <div class="title-container">
                                                                <div class="
                                    d-flex
                                    align-center
                                    flex-drirection-row
                                  ">
                                                                    <img :src="productImage || dishPlaceHolder"
                                                                        alt="dish image" class="dialogImage" />
                                                                </div>
                                                            </div>
                                                            <div class="info-container">
                                                                <div class="icons-container-1">

                                                                    <div class="">
                                                                        <p class="info-price">
                                                                            {{ product.productPrice ?
                                                                                product.productPrice.toLocaleString('ru-RU')
                                                                                : '0' }} ₸
                                                                        </p>
                                                                        <div class="icons-container">
                                                                            <img :src="product.isAvailableInPlace
                                                                                ? inHollGreen
                                                                                : inHollGray
                                                                                " alt="В зале" />
                                                                            <img :src="product.isAvailableForDelivery
                                                                                ? deliveryGreen
                                                                                : deliveryGray
                                                                                " alt="Доставка" />
                                                                            <img :src="product.isAvailableForDelivery
                                                                                ? takeAndGoGreen
                                                                                : takeAndGoGray
                                                                                " alt="Навынос" />
                                                                        </div>

                                                                    </div>
                                                                    <div class="dateContainer">
                                                                        <p class="dateTitle">Дата создания
                                                                        </p>
                                                                        <b class="dateDescription">{{
                                                                            formatDate(product.createdOn)
                                                                        }}</b>
                                                                    </div>
                                                                    <div class="dateContainer">
                                                                        <p class="dateTitle">
                                                                            Дата изменения
                                                                        </p>
                                                                        <b class="dateDescription">{{
                                                                            formatDate(product.modifiedOn)
                                                                        }}</b>
                                                                    </div>




                                                                </div>
                                                                <div class="locale-container-1">
                                                                    <div class="" style="width: 100%;">
                                                                        <div
                                                                            v-if="product.productLocalizations && product.productLocalizations.length">
                                                                            <div class="locale-1"
                                                                                v-for="productItem in product.productLocalizations"
                                                                                v-bind:key="productItem.name">
                                                                                <div class="locale-title-container">
                                                                                    <div class="localeContainer">
                                                                                        {{ productItem.culture }}
                                                                                    </div>
                                                                                    <h4 class="product-title">
                                                                                        {{ productItem.name }}
                                                                                    </h4>
                                                                                </div>
                                                                                <p class="product-description">
                                                                                    {{ productItem.description }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <h4 v-else class="product-title">
                                                                            {{ product.productName }}
                                                                        </h4>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-item-second">
                                                        <div style="
                                display: flex;
                                align-items: center;
                                gap: 5px;
                              ">
                                                            <img src="../assets/icons/dish.svg" alt="Блюдо иконка" />
                                                            <h4 class="card-item-second-title">Детали</h4>
                                                        </div>
                                                        <hr />
                                                        <div>
                                                            <div>
                                                                <h5 class="product-total-title">Продажи</h5>
                                                                <b class="product-total-price">{{ product.totalAmount ?
                                                                    product.totalAmount.toLocaleString('ru-RU') : '0' }}
                                                                    ₸</b>
                                                            </div>
                                                            <div style="
                                  margin-top: 20px;
                                  display: flex;
                                  
                                ">
                                                                <div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Процент продаж
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.orderPercentage }}%
                                                                        </p>
                                                                    </div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Продано поцизий
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.soldCount }}
                                                                        </p>
                                                                    </div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Процент от проданных поцизий
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.soldPercentage }}%
                                                                        </p>
                                                                    </div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Заказов
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.orderCount }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div style="margin-left: 20px">
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Модификатор
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.isModificator ? "Есть" : "Нет"
                                                                            }}
                                                                        </p>
                                                                    </div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Опции
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            {{ product.isOption ? "Есть" : "Нет"
                                                                            }}
                                                                        </p>
                                                                    </div>
                                                                    <!-- <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Популярный модификатор
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            Отсуствует
                                                                        </p>
                                                                    </div>
                                                                    <div class="product-details-items">
                                                                        <h6 class="product-details-items-title">
                                                                            Популярна опция
                                                                        </h6>
                                                                        <p class="product-details-items-description">
                                                                            Отсуствует
                                                                        </p>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="order-info-container">
                                                            <div class="order-info-containers">
                                                                <p class="order-info-text-green">Кол-во</p>
                                                                <p class="order-info-text-green">Сумма</p>
                                                            </div>
                                                            <div class="divider-modal"></div>

                                                            <div class="mt-2">
                                                                <p class="order-title">В зале</p>
                                                                <div class="order-info-containers-1">
                                                                    <p class="order-info-text-second">
                                                                        {{ product.inHouseOrderCount }}
                                                                    </p>
                                                                    <p class="order-info-text-second">
                                                                        {{ product.inHouseTotalAmount ?
                                                                            product.inHouseTotalAmount.toLocaleString('ru-RU')
                                                                            : '0' }} ₸
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="divider-modal mt-0"></div>
                                                            <div class="mt-2">
                                                                <p class="order-title">Доставка</p>
                                                                <div class="order-info-containers-1">
                                                                    <p class="order-info-text-second">
                                                                        {{ product.deliveryOrderCount }}
                                                                    </p>
                                                                    <p class="order-info-text-second">
                                                                        {{ product.deliveryTotalAmount ?
                                                                            product.deliveryTotalAmount.toLocaleString('ru-RU')
                                                                            : '0' }} ₸
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="divider-modal mt-0"></div>
                                                            <div class="mt-2">
                                                                <p class="order-title">Навынос</p>
                                                                <div class="order-info-containers-1">
                                                                    <p class="order-info-text-second">
                                                                        {{ product.selfPickUpOrderCount }}
                                                                    </p>
                                                                    <p class="order-info-text-second">
                                                                        {{ product.selfPickUpTotalAmount ?
                                                                            product.selfPickUpTotalAmount.toLocaleString('ru-RU')
                                                                            : '0' }} ₸
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="divider-modal mt-0"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-dialog>
                                    </v-toolbar>

                                    <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                                        {{ message }}
                                        <template v-slot:action="{ attrs }">
                                            <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                                                Закрыть
                                            </div>
                                        </template>
                                    </v-snackbar>
                                </template>

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td @click="getProduct(item)">
                                            <div class="statcode-container">
                                                <div v-if="item.stateCode === 'Active'" class="green-circle"></div>
                                                <div v-else class="red-circle"></div>
                                                {{ formatProductName(item.productName) }}
                                            </div>
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.totalAmount.toLocaleString("ru-RU") }} ₸
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.orderPercentage }} %
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.soldCount }}
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.soldPercentage }} %
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.orderCount }}
                                        </td>

                                        <td @click="getProduct(item)">
                                            {{ item.inHouseTotalAmount.toLocaleString("ru-RU") }} ₸
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.inHouseOrderCount }}
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.selfPickUpTotalAmount.toLocaleString("ru-RU") }} ₸
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.selfPickUpOrderCount }}
                                        </td>

                                        <td @click="getProduct(item)">
                                            {{ item.deliveryTotalAmount.toLocaleString("ru-RU") }} ₸
                                        </td>
                                        <td @click="getProduct(item)">
                                            {{ item.deliveryOrderCount }}
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:no-data>
                                    <div v-if="showNoDataMessage">
                                        Не найденно подходящих записей.
                                    </div>
                                    <v-progress-circular v-else-if="isLoadingGuest" indeterminate
                                        color="primary"></v-progress-circular>
                                </template>
                                <template v-slot:footer>
                                    <div class="v-data-footer">
                                        <div class="v-data-footer__select">
                                            Кол-во строк на странице:
                                            <v-select class="custom-select" v-model="itemsPerPage"
                                                :items="footerProps.itemsPerPageOptions" @change="changeItemsPerPage"
                                                hide-details></v-select>
                                        </div>
                                        <div class="v-data-footer__pagination">
                                            {{ (currentPage - 1) * itemsPerPage + 1 }} -
                                            {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                                            {{ totalItems }}
                                        </div>
                                        <div class="v-data-footer__icons-before">
                                            <button type="button" :disabled="currentPage === 1" class="
                          v-btn v-btn--icon v-btn--round v-btn--text
                          theme--light
                          v-size--default
                        " :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                                                @click="goToPreviousPage">
                                                <span class="v-btn__content">
                                                    <i aria-hidden="true" class="
                              v-icon
                              notranslate
                              mdi mdi-chevron-left
                              theme--light
                            "></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="v-data-footer__icons-after">
                                            <button type="button" :disabled="currentPage === totalPages" class="
                          v-btn v-btn--icon v-btn--round v-btn--text
                          theme--light
                          v-size--default
                        " :class="{
                            'v-btn--disabled': currentPage === totalPages,
                        }" aria-label="Следующая страница" @click="goToNextPage">
                                                <span class="v-btn__content">
                                                    <i aria-hidden="true" class="
                              v-icon
                              notranslate
                              mdi mdi-chevron-right
                              theme--light
                            "></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import dishPlaceHolder from "../assets/img/dishPlaceHolder.svg";

import inHollGreen from "../assets/icons/inHollGreen.svg";
import inHollGray from "../assets/icons/inHollGray.svg";

import deliveryGreen from "../assets/icons/deliveryGreen.svg";
import deliveryGray from "../assets/icons/deliveryGray.svg";

import takeAndGoGreen from "../assets/icons/takeAndGoGreen.svg";
import takeAndGoGray from "../assets/icons/takeAndGoGray.svg";

import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import moment from "moment";
import DoughnutOrders from "../components/charts/DoughnutOrders.vue";
// import HorizontalBarChart from "../components/charts/HorizontalBarChart.vue";
export default {
    components: {
        DoughnutOrders,
        // HorizontalBarChart
    },

    directives: { mask },
    name: "PopularDishes",

    data: () => ({
        takeAndGoGreen,
        takeAndGoGray,
        deliveryGreen,
        deliveryGray,
        inHollGreen,
        inHollGray,
        dishPlaceHolder,
        selectedProduct: 0,
        itemsProduct: [
            { text: "Все", value: 0 },
            { text: "С продажами", value: 1 },
            { text: "Без продаж", value: 2 },
        ],
        currentValue: 50,
        previousValue: 30,
        pickUpTypeOptions: [
            { text: "Доставка", value: "Delivery" },
            { text: "Навынос", value: "SelfPickUp" },
            { text: "В зале", value: "InHouse" },
        ],
        isDownloading: false,
        isDataLoaded: false,
        formattedDataReady: false,
        downLoadOrderData: {
            items: [],
        },
        awaitFormattedData: [],
        chartData: [
            {
                title: "Кавказская нарезка",
                currentStatistic: 650,
                prevStatistic: 550,
                percent: 8,
            },
        ],
        columns: [
            { label: "Наименование", field: "productName" },
            { label: "Продажи", field: "totalAmount" },
            { label: "Процент продаж", field: "orderPercentage" },
            { label: "Продано позиций", field: "soldCount" },
            { label: "Процент от проданных позиций", field: "soldPercentage" },
            { label: "Заказов", field: "orderCount" },
            { label: "В зале (Сумма)", field: "inHouseTotalAmount" },
            { label: "В зале (Кол-во)", field: "inHouseOrderCount" },
            { label: "Навынос (Сумма)", field: "selfPickUpTotalAmount" },
            { label: "Навынос (Кол-во)", field: "selfPickUpOrderCount" },
            { label: "Доставка (Сумма)", field: "deliveryTotalAmount" },
            { label: "Доставка (Кол-во)", field: "deliveryOrderCount" },
            { label: "Статус блюда", field: "stateCode" },
        ],
        selectedRestaurantName: "",
        imageHost: process.env.VUE_APP_IMAGE_API_HOST,
        progressValueFull: 100,
        restaurantsList: [],
        markers: [
            { label: "в приложении", value: 1200, position: 12 },
            { label: "всего", value: 25000, position: 100 },
        ],
        sortOrder: {},
        showArrows: null,
        orderData: {},
        product: {},
        productsType: null,
        // contactAddressList: [],
        productDialog: false,
        activeBlock: 0,
        noDataMessage: "",
        selectedRestaurant: null, // Хранит выбранный ресторан
        isLoading: true,
        isLoadingGuest: true,
        dialog: false, // Скрываем календарь по умолчанию
        selectedDate: moment(new Date()).format("YYYY-MM-DD"),
        workStartDate: "", // Текущая выбранная дата
        dateType: "date", // Выбор по умолчанию: месяц
        dataTypePeriod: "",
        formattedDateRange: "",
        formattedDateRangeProd: "",
        formattedCompareDataRange: "",
        formattedCompareDataRangeProd: "",
        startDate: "",
        endDate: "", // Диапазон, отображаемый пользователю // Для отображения диапазона дат

        fullName: "",
        brandName: "",

        search: "",
        snackbar: false,
        message: "",

        newAcc: {},
        account: {},
        selectedType: null,
        rolesTest: [
            { label: "CustomerApp", value: 1 },
            { label: "RestaurantApp", value: 2 },
            { label: "WebApp", value: 3 },
        ],

        headers: [
            {
                text: "Название",
                align: "start",
                value: "productName",
                sortable: false
                // filterable: true,
            },
            { text: "Продажи", value: "totalAmount", width: "200px" },
            { text: "Процент продаж", value: "orderPercentage", width: "154px" },
            { text: "Продано позиций", value: "soldCount" },
            { text: "Процент от проданных позиций", value: "soldPercentage" },
            { text: "Заказов", value: "orderCount" },
            { text: "В зале (Сумма)", value: "inHouseTotalAmount", },
            { text: "В зале (Кол-во)", value: "inHouseOrderCount", },
            { text: "Навынос (Сумма)", value: "selfPickUpTotalAmount" },
            { text: "Навынос (Кол-во)", value: "selfPickUpOrderCount" },
            { text: "Доставка (Сумма)", value: "deliveryTotalAmount", },
            { text: "Доставка (Кол-во)", value: "deliveryOrderCount" },
        ],
        accounts: [],
        profiles: [],
        error: null,
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        totalPages: 0,
        currentAcc: "",
        formattedData: [],
        showNoDataMessage: false,
        ticks: [
            { value: 0, position: 0 },
            { value: 25, position: 25 },
            { value: 50, position: 50 },
            { value: 75, position: 75 },
            { value: 100, position: 100 },
        ],
        productImage: null,
        currentLevelStart: "",
        currentLevelEnd: "",
        currentMoneySpend: "",
        nextLevel: "",
        options: {
            page: 1, // текущая страница
            itemsPerPage: 10, // количество записей на страницу
            sortBy: [], // параметр сортировки
            sortDesc: [], // направление сортировки (по убыванию)
        },
        columnFilters: {
            OrderNumber: "",
            PhoneNumber: "",
            pickUpType: "",
            // Добавьте другие поля для фильтрации...
        },
        filterableColumns: ["OrderNumber", "PhoneNumber", "PickUpType"],
    }),

    created() {
        // this.initialize();
        // this.profileInfo();
        this.setWeeklyDateRange();
        this.getDashboardInformation();
        this.getProductsList();
        this.getAllRestaurants();
        // this.restaurants.push(this.selectedRestaurant)
        console.log(this.restaurantsList);
        this.headers.forEach((header) => {
            this.$set(this.sortOrder, header.value, "default");
        });
        if (this.restaurantsList.length === 1) {
            this.selectedRestaurant = this.restaurantsList[0].restaurantId;
        }
    },
    computed: {
        // formattedData() {
        //     if (this.isLoadingGuest || !this.formattedDataReady) {
        //         console.log("Data is loading or not ready yet.");
        //         return [];
        //     }

        //     return this.downLoadOrderData.items.map((item) => ({
        //         ...item,
        //         date: this.formatDate(item.date),  // Форматируем дату
        //     }));
        // },

        filteredGuests() {
            // Фильтруем данные внутри таблицы в зависимости от выбранного типа
            if (this.selectedType) {
                console.log(this.selectedType);
                return this.orderData.items
                    .filter
                    // (item) => item.rolesTest === this.selectedType
                    ();
            } else {
                return this.orderData.items;
            }
        },
        footerProps() {
            return {
                itemsPerPageOptions: [5, 10, 25, 50], // Опции количества элементов на странице
                itemsPerPage: this.itemsPerPage,
                currentPage: this.currentPage,
                totalItems: this.totalItems,
            };
        },
        ...mapGetters({
            user: "user",
        }),
        textareaPlaceholder() {
            return this.isPlaceholderHidden ? "" : "---";
        },
        formattedLevelStart() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelStart).toLocaleString();
        },
        formattedLevelEnd() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentLevelEnd).toLocaleString();
        },
        formattedMoneySpend() {
            // Преобразуем строку в число и применяем форматирование
            return Number(this.currentMoneySpend).toLocaleString();
        },
    },

    mounted() {
        this.getDashboardInformation();
        this.setInitialDateRange();
    },

    methods: {
        handleProduct(value) {
            this.currentPage = 1;
            this.search = "";
            this.setProductType(value);
        },
        setProductType(type) {
            this.productsType = type;
            this.activeBlock = type;
            this.getProductsList();
        },
        updateSort(value) {
            if (this.options.sortBy[0] === value) {
                this.options.sortDesc[0] = !this.options.sortDesc[0];
            } else {
                this.options.sortBy = [value];
                this.options.sortDesc = [false];
            }
            this.getProductsList(); // вызов для применения сортировки на сервере
        },
        downloadData() {
            if (this.downLoadOrderData.items.length === 0) {
                console.log("No data in downLoadOrderData.items. Loading data...");
                this.getProductsListDownload().then(() => {
                    // После загрузки данных обновляем formattedData для инициирования скачивания
                    this.formattedData = this.downLoadOrderData.items.map((item) => ({
                        ...item,
                        stateCode: this.formatStateCode(item.stateCode),
                        //{{ item.inHouseTotalAmount.toLocaleString('ru-RU') }}₸

                        //paymentMethodTypeDownload: this.paymentMethod(item.paymentMethodType),
                    }));
                    this.triggerDownload();
                });
            } else {
                this.formattedData = [];
                this.downLoadOrderData.items = [];
                // console.log("Data already loaded:", this.downLoadOrderData.items);
                // this.formattedData = this.downLoadOrderData.items.map((item) => ({
                //     ...item,
                //     date: this.formatDate(item.date),
                // }));
                // this.triggerDownload();
            }
        },
        triggerDownload() {
            // Проверяем, не идет ли уже скачивание
            if (this.isDownloading) {
                console.log("Download already in progress.");
                return;
            }

            this.isDownloading = true; // Устанавливаем флаг, что скачивание началось

            // Используем $nextTick для гарантии, что данные обновлены
            this.$nextTick(() => {
                const excelBtn = this.$refs.excelBtn;
                if (excelBtn && excelBtn.$el) {
                    excelBtn.$el.click(); // Имитация клика для инициирования скачивания
                }
            });

            // Сбрасываем флаг после завершения скачивания (это можно сделать вручную, если у вас есть событие по окончанию скачивания)
            setTimeout(() => {
                this.isDownloading = false;
                this.formattedData = [];
                this.downLoadOrderData.items = []; // Сбрасываем флаг спустя некоторое время (например, 1 секунда после скачивания)
            }, 500); // Замените на более точное время в зависимости от вашего процесса скачивания
        },

        onRestaurantChange(item) {
            // Здесь вы обновляете название ресторана
            const selectedRestaurantObj = this.restaurantsList.find(
                (restaurant) => restaurant.restaurantId === this.selectedRestaurant
            );
            if (selectedRestaurantObj) {
                this.selectedRestaurantName = selectedRestaurantObj.restaurant.name;
            } else {
                this.selectedRestaurantName = null; // Это можно для ясности оставить, хотя оно и так будет null по умолчанию
            }
            this.currentPage = 1;
            this.productsType = null;
            this.activeBlock = 0;
            this.getDashboardInformation();
            this.getProductsList();
        },
        // Функция для выгрузки в Excel
        getExcelFileName() {
            if (this.productsType === 1) {
                return `С продажами-${this.formattedDateRangeProd}-${this.selectedRestaurantName || "все рестораны"
                    }`;
            }
            if (this.productsType === 2) {
                return `Без продаж-${this.formattedDateRangeProd}-${this.selectedRestaurantName || "все рестораны"
                    }`;
            } else {
                return `Популярные блюда-${this.formattedDateRangeProd}-${this.selectedRestaurantName || "все рестораны"
                    }`;
            }
            // Если название ресторана пустое, пишем "все рестораны"
        },

        calculateTimeDifference(item) {
            // Функция для правильного склонения слов
            const declension = (number, words) => {
                const cases = [2, 0, 1, 1, 1, 2];
                return words[
                    number % 100 > 4 && number % 100 < 20
                        ? 2
                        : cases[Math.min(number % 10, 5)]
                ];
            };
            if (item.closedOn == null) {
                return "Не указанна";
            } else {
                const openedDate = new Date(item.openedOn);
                const closedDate = new Date(item.closedOn);
                const differenceInMs = closedDate - openedDate;
                // Вычисляем часы и минуты
                const totalMinutes = Math.floor(differenceInMs / (1000 * 60));
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                // Форматируем результат и добавляем подписи
                let result = "";

                if (hours > 0) {
                    // Если ровно час, не указываем минуты
                    result += `${hours} ${declension(hours, ["час", "часа", "часов"])}`;
                    if (minutes > 0) {
                        result += ` ${minutes} мин`; // Сокращение для минут
                    }
                } else {
                    const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);
                    result += `${minutes} мин`; // Сокращение для минут
                    if (seconds > 0) {
                        result += ` ${seconds} сек`; // Сокращение для секунд
                    }
                }

                return result.trim();
            } // Удаляем лишние пробелы
        },
        computedDiscount(item) {
            const discount = item.totalBonusAmount + item.totalCouponAmount;
            return discount > 0 ? `${discount.toLocaleString("ru-RU")} ₸` : "0 ₸";
        },
        computedTotalAmountOfProduct(item) {
            const totalPrice = item.qty * item.resultPrice;
            return totalPrice > 0 ? `${totalPrice.toLocaleString("ru-RU")} ₸` : "0 ₸";
        },
        restNameFormat(restaurantName) {
            return restaurantName || "Не указано";
        },
        formatProductName(productName) {
            if (!productName) return "Не указано";
            return productName.length > 20
                ? productName.slice(0, 20) + "..."
                : productName;
        },
        adressFormat(deliveryAddress, restaurantAddress) {
            // Если deliveryAddress не указан, возвращаем restaurantAddress
            if (!deliveryAddress) {
                return restaurantAddress || "Адрес ресторана не указан";
            }

            const { street, district, house, apt, floor, entrance, comment } =
                deliveryAddress;

            // Формирование массива с уточнениями
            const addressParts = [
                street ? `${street}` : null,
                district ? `${district}` : null,
                house ? `д. ${house}` : null,
                apt ? `кв. ${apt}` : null,
                floor ? `этаж ${floor}` : null,
                entrance ? `подъезд ${entrance}` : null,
                comment ? `Комментарий: ${comment}` : null,
            ];

            // Исключение полей с null и объединение оставшихся через запятую
            return (
                addressParts.filter((part) => part !== null).join(", ") ||
                "Адрес не указан"
            );
        },
        formatName(guestName) {
            return guestName || "Не указано";
        },

        formatOrderStatus(orderStatus) {
            switch (orderStatus) {
                case "Delivered":
                    return "Доставлен";
                case "Canceled":
                    return "Отменен";
                case "Expired":
                    return "Истекший заказ";
                default:
                    return orderStatus;
            }
        },
        formatStateCode(stateCode) {
            switch (stateCode) {
                case "Active":
                    return "Активный";
                case "Disabled":
                    return "Не активный";
                case "Inactive":
                    return "Не активный";
                default:
                    return stateCode;
            }
        },
        paymentMethod(paymentMethodType) {
            switch (paymentMethodType) {
                case "BankCard":
                    return "Банковская карта";
                case "Cash":
                    return "Наличные";
                default:
                    return paymentMethodType;
            }
        },

        formatPickUpType(pickUpType) {
            switch (pickUpType) {
                case "Delivery":
                    return "Доставка";
                case "SelfPickUp":
                    return "Навынос";
                case "InHouse":
                    return "В зале";
                default:
                    return pickUpType;
            }
        },
        formatReviewType(reviewTypes) {
            switch (reviewTypes) {
                case "Courier":
                    return "Работа курьера";
                case "Food":
                    return "Вкус еды";
                case "Package":
                    return "Качество упаковки";
                case "Service":
                    return "Качество сервиса";
                case "Atmosphere":
                    return "Атмосфера ресторана";
                default:
                    return reviewTypes;
            }
        },

        // Расчет процента прогресса от 0 до currentLevelEnd
        progressValue(guestLevel) {
            const end = parseInt(guestLevel.spentAmountTo);
            const spent = parseInt(guestLevel.spentAmount);

            // Вычисляем процент прогресса относительно всего диапазона (0 до currentLevelEnd)
            const percent = (spent / end) * 100;

            // Убедитесь, что значение находится в пределах 0-100
            return Math.max(0, Math.min(100, percent));
        },
        // Вычисляем оставшуюся сумму до конца уровня
        remainingAmount(guestLevel) {
            const end = parseInt(guestLevel.spentAmountTo);
            const spent = parseInt(guestLevel.spentAmount);
            const remaining = end - spent; // Остаток до currentLevelEnd
            return remaining.toLocaleString(); // Преобразуем в строку с разделителями
        },
        formatPhoneNumber(phoneNumber) {
            if (!phoneNumber) return "";
            const rawPhone = phoneNumber.replace(/\D/g, ""); // Убираем все символы, кроме цифр
            return `+7 ${rawPhone.substring(1, 4)} ${rawPhone.substring(
                4,
                7
            )} ${rawPhone.substring(7, 9)} ${rawPhone.substring(9, 11)}`;
        },
        formatDate(dateString) {
            if (!dateString) {
                return "Не указана"; // или любое значение по умолчанию
            }
            const date = new Date(dateString);
            return `${String(date.getDate()).padStart(2, "0")}.${String(
                date.getMonth() + 1
            ).padStart(2, "0")}.${date.getFullYear()}`;
        },
        formatHourMin(dateString) {
            if (!dateString) {
                return "Не указана"; // или любое значение по умолчанию
            }
            const date = new Date(dateString);
            return `${String(date.getHours()).padStart(2, "0")}:${String(
                date.getMinutes()
            ).padStart(2, "0")}`;
        },
        waiterNameFormat(waiterName) {
            return waiterName || "Не указано";
        },
        handleSort(column) {
            // Проверяем текущее состояние сортировки для колонки и переключаем его
            console.log(this.sortOrder[column]);
            if (this.sortOrder[column] === "default") {
                this.$set(this.sortOrder, column, "ascending");
                this.sortData(column, "ascending");
            } else if (this.sortOrder[column] === "ascending") {
                this.$set(this.sortOrder, column, "descending");
                this.sortData(column, "descending");
            } else {
                this.$set(this.sortOrder, column, "default");
                this.resetSort(column);
            }
        },
        sortData(column, order) {
            // Логика сортировки данных для конкретного столбца
            console.log(`Сортировка ${column}: ${order}`);
        },
        resetSort(column) {
            // Логика сброса сортировки
            console.log(`Сброс сортировки для ${column}`);
        },
        // sortData(value) {
        //   this.sortDesc = this.sortBy === value ? !this.sortDesc : false; // Переключаем направление сортировки
        //   this.sortBy = value;

        //   this.items.sort((a, b) => {
        //     if (this.sortDesc) {
        //       return a[value] < b[value] ? 1 : -1;
        //     } else {
        //       return a[value] > b[value] ? 1 : -1;
        //     }
        //   });
        // },
        getAllRestaurants() {
            let allRest = {
                restaurantId: null,
                restaurant: { name: "Все рестораны" },
            };
            console.log(this.user.roles); // Для отладки

            // Получаем рестораны из ролей пользователя
            this.restaurantsList = [...this.user.roles];

            // Проверяем, если ресторанов больше одного, добавляем "Все рестораны" в начало списка
            if (this.restaurantsList.length > 1) {
                this.restaurantsList.unshift(allRest);
            }

            console.log(this.restaurantsList);
        },

        getCompareOrders() {
            this.formattedDateRangeProd = this.formattedDateRange;
            this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
            this.dialog = false;
            this.productsType = null;
            this.activeBlock = 0;
            this.currentPage = 1;
            this.getDashboardInformation();
            this.getProductsList();
        },

        setWeeklyDateRange() {
            // Устанавливаем диапазон для текущей недели
            const today = moment(); // Текущая дата
            this.dataTypePeriod = 0;
            this.startDate = today.startOf("isoWeek").format("YYYY-MM-DD");
            this.endDate = today.endOf("isoWeek").format("YYYY-MM-DD");
            console.log(this.startDate);
            console.log(this.endDate);
            this.formattedDateRange = `${this.startDate} - ${this.endDate}`;
            this.formattedDateRangeProd = this.formattedDateRange;
            //   this.startDate = this.startDate.toISOString();
            //   this.endDate = this.endDate.toISOString();
        },

        getDashboardInformation() {
            this.isLoading = true;

            // Первый запрос - получение информации о клиентах
            this.$http
                .get("/api/Dashboard/GetProductsStatistics", {
                    params: {
                        restaurantId: this.selectedRestaurant,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        dataType: this.dataTypePeriod,
                    },
                })
                .then((response) => {
                    if (
                        response.status === 204 ||
                        !response.data ||
                        response.data.deliveredOrderCount == 0
                    ) {
                        this.noDataMessage = "За выбранный период данных нет.";
                        this.dashboardInfo = {};
                        this.markers = [];
                    } else {
                        this.dashboardInfo = response.data;
                        this.noDataMessage = "";
                        this.markers = [
                            {
                                label: "API",
                                value: this.dashboardInfo.deliveredOrderCount, // Укажите нужное поле из API
                                position: 10, // Укажите нужное поле из API
                            },
                            { label: "всего", value: 25000, position: 100 },
                        ];
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.error("Error fetching dashboard information:", error);
                });
        },
        async getProductsListDownload() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            this.isLoadingGuest = true;
            console.log("Loading data...");

            try {
                const orderResponse = await this.$http.put(
                    "/api/Dashboard/GetProductsPagedList",
                    {
                        pageNumber: 1,
                        pageSize: this.totalItems,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            restaurantId: this.selectedRestaurant,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            dataType: this.dataTypePeriod,
                            productType: this.productsType,
                        },
                    }
                );

                if (orderResponse.status === 204 || !orderResponse.data) {
                    console.warn("No guest data for the selected period.");
                } else {
                    console.log("Data received from API:", orderResponse.data);
                    this.downLoadOrderData.items = orderResponse.data.items;
                    console.log(
                        "Data written to downLoadOrderData.items:",
                        this.downLoadOrderData.items
                    );

                    this.isLoadingGuest = false;
                    this.showNoDataMessage = this.totalItems === 0;

                    // Устанавливаем флаг, что данные загружены
                    this.isDataLoaded = true;
                }
            } catch (error) {
                console.error("Error fetching guest information:", error);
                this.isLoadingGuest = false;
            }
        },
        getProductsList() {
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            this.isLoadingGuest = true;
            this.orderData = {};
            const filters = Object.keys(this.columnFilters)
                .filter((key) => this.columnFilters[key]) // Оставляем только активные фильтры
                .map((key) => ({
                    property: key,
                    operator: "eq",
                    value: this.columnFilters[key],
                }));
            this.$http
                .put(
                    "/api/Dashboard/GetProductsPagedList",
                    {
                        pageNumber: this.currentPage,
                        pageSize: this.itemsPerPage,
                        // filters: filters,
                        filters: [
                            {
                                property: "ProductName",
                                operator: "contains", // "i" означает insensitive (нечувствительный к регистру)
                                value: this.search,
                            },
                            // {
                            //     property: "OrderNumber",
                            //     operator: "contains",
                            //     value: this.search
                            // },
                        ],
                        sorting: sortBy.length
                            ? {
                                property: sortBy[0], // поле для сортировки
                                isDescending: sortDesc[0] || false, // направление сортировки
                            }
                            : null,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            restaurantId: this.selectedRestaurant,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            dataType: this.dataTypePeriod,
                            productType: this.productsType,
                        },
                    }
                )
                .then((orderResponse) => {
                    // Обрабатываем ответ второго запроса
                    if (orderResponse.status === 204 || !orderResponse.data) {
                        console.warn("No guest data for the selected period.");
                    } else {
                        this.orderData = orderResponse.data;
                        this.totalPages = orderResponse.data.totalPages; // Общее количество страниц
                        this.totalItems = orderResponse.data.totalCount;
                        if (this.totalItems === 0) {
                            this.showNoDataMessage = true;
                            this.isLoadingGuest = false;
                        } else {
                            this.showNoDataMessage = false;
                            this.isLoadingGuest = false;
                        } // Общее количество элементов
                    }
                })
                .catch((error) => {
                    console.error("Error fetching guest information:", error);
                });
        },
        setInitialDateRange() {
            if (this.dateType === "month") {
                this.dataTypePeriod = 1;

                // Устанавливаем диапазон для текущего месяца
                const startDate = moment(this.selectedDate)
                    .startOf("month")
                    .format("DD.MM.YYYY");
                const endDate = moment(this.selectedDate)
                    .endOf("month")
                    .format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD");

                // Вычисляем предыдущий месяц
                const previousMonth = moment(this.selectedDate).subtract(1, "month");
                console.log(previousMonth);

                const previousMonthStart = moment(previousMonth)
                    .startOf("month")
                    .format("DD.MM.YYYY");
                console.log(previousMonthStart);
                const previousMonthEnd = moment(previousMonth)
                    .endOf("month")
                    .format("DD.MM.YYYY");
                console.log(previousMonthEnd);
                this.formattedCompareDataRange = `${previousMonthStart} - ${previousMonthEnd}`;
                this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
                // Сравнение текущего и предыдущего месяца
                //this.formattedDateRange = `Текущий месяц: ${startDate} - ${endDate}, Предыдущий месяц: ${previousMonthStart} - ${previousMonthEnd}`;
            } else if (this.dateType === "date") {
                if (!moment(this.selectedDate, "YYYY-MM-DD", true).isValid()) {
                    this.selectedDate = moment(new Date()).format("YYYY-MM-DD");
                }
                this.dataTypePeriod = 0;

                const startOfWeek = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");
                const endOfWeek = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("DD.MM.YYYY");

                const previousWeek = moment(this.selectedDate).subtract(1, "isoWeek");

                const previousWeekStart = moment(previousWeek)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");

                const previousWeekEnd = moment(previousWeek)
                    .endOf("isoWeek")
                    .format("DD.MM.YYYY");

                this.formattedCompareDataRange = `${previousWeekStart} - ${previousWeekEnd}`;
                this.formattedCompareDataRangeProd = this.formattedCompareDataRange;
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
                this.formattedDateRangeProd = this.formattedDateRange;
            }
            // this.getDashboardInformation();
        },

        setDateType(type) {
            if (this.dateType !== type) {
                this.dateType = type;
                // Устанавливаем выбранную дату в зависимости от типа
                if (this.dateType === "month") {
                    // Если переключаемся на месяц, используем текущий месяц
                    this.selectedDate = moment(new Date()).format("YYYY-MM-DD");
                } else {
                    // Для недели используем текущую дату
                    this.selectedDate = moment(new Date()).format("YYYY-MM-DD");
                }
                // Устанавливаем диапазон дат
                this.setInitialDateRange();
                this.$forceUpdate();
            }
        },
        onDateChange(date) {
            if (this.dateType === "month") {
                // Если выбран месяц, устанавливаем диапазон на месяц
                this.dataTypePeriod = 1;

                const startDate = moment(date).startOf("month").format("DD.MM.YYYY");
                const endDate = moment(date).endOf("month").format("DD.MM.YYYY");
                this.startDate = moment(this.selectedDate)
                    .startOf("month")
                    .format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate)
                    .endOf("month")
                    .format("YYYY-MM-DD");
                const previousMonth = moment(this.selectedDate).subtract(1, "month");
                console.log(previousMonth);

                const previousMonthStart = moment(previousMonth)
                    .startOf("month")
                    .format("DD.MM.YYYY");
                console.log(previousMonthStart);
                const previousMonthEnd = moment(previousMonth)
                    .endOf("month")
                    .format("DD.MM.YYYY");
                console.log(previousMonthEnd);
                this.formattedCompareDataRange = `${previousMonthStart} - ${previousMonthEnd}`;
                this.formattedDateRange = `${startDate} - ${endDate}`;
            } else if (this.dateType === "date") {
                // Если выбрана неделя, вычисляем начало и конец недели
                const startOfWeek = moment(date)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");
                const endOfWeek = moment(date).endOf("isoWeek").format("DD.MM.YYYY");
                this.dataTypePeriod = 0;

                this.startDate = moment(this.selectedDate)
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                this.endDate = moment(this.selectedDate)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");

                const previousWeek = moment(this.selectedDate).subtract(1, "isoWeek");

                const previousWeekStart = moment(previousWeek)
                    .startOf("isoWeek")
                    .format("DD.MM.YYYY");

                const previousWeekEnd = moment(previousWeek)
                    .endOf("isoWeek")
                    .format("DD.MM.YYYY");

                this.formattedCompareDataRange = `${previousWeekStart} - ${previousWeekEnd}`;
                this.formattedDateRange = `${startOfWeek} - ${endOfWeek}`;
            }
        },
        allowedDates(date) {
            // Разрешаем выбирать только недели или месяцы
            if (this.selectedRestaurant) {
                const restaurant = this.restaurantsList.find(
                    (item) => item.restaurantId === this.selectedRestaurant
                );
                this.workStartDate = restaurant
                    ? restaurant.restaurant.createdOn
                    : null;
            } else {
                // Если ресторан не выбран, находим самую позднюю workStartDate среди всех ресторанов
                const earliestDate = this.restaurantsList.reduce(
                    (minDate, restaurant) => {
                        const currentStartDate = moment(restaurant.restaurant.createdOn);
                        return currentStartDate.isBefore(minDate)
                            ? currentStartDate
                            : minDate;
                    },
                    moment(this.restaurantsList[0].restaurant.createdOn)
                );
                this.workStartDate = earliestDate.toISOString();
            }
            const workStartMoment = moment(this.workStartDate);
            const currentMoment = moment();
            // Разрешаем только даты после workStartDate
            if (
                moment(date).isBefore(workStartMoment, "month") ||
                moment(date).isAfter(currentMoment, "day")
            ) {
                return false;
            }

            // Ограничиваем выбор дат в зависимости от типа
            if (this.dateType === "month") {
                return true;
            } else if (this.dateType === "date") {
                return moment(date).day() === 1; // Позволяем выбирать недели (по понедельникам)
            }

            return false;
        },
        initialize() {
            this.$http
                .put("/api/Account/PagedList", {
                    pageNumber: this.currentPage,
                    pageSize: this.itemsPerPage,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    filters: [
                        {
                            property: "ProductName",
                            operator: "contains",
                            value: this.search,
                        },
                    ],
                })
                .then((response) => {
                    this.accounts = response.data;
                    this.totalPages = response.data.totalPages; // Общее количество страниц
                    this.totalItems = response.data.totalCount;
                    if (this.totalItems === 0) {
                        this.showNoDataMessage = true;
                    } else {
                        this.showNoDataMessage = false;
                    }
                })
                .catch((error) => (this.error = error));
        },
        goToNextPage() {
            if (this.currentPage < this.totalItems) {
                this.currentPage++;
                this.getProductsList();
            }
        },
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getProductsList();
            }
        },
        changeItemsPerPage() {
            this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
            this.getProductsList();
            // Вызов метода для загрузки данных с новым количеством элементов на странице
        },

        filtersItems() {
            this.currentPage = 1;
            this.productsType = null;
            this.activeBlock = 0;
            this.getDashboardInformation();
            this.getProductsList();
        },
        getProduct(item) {
            console.log(item);

            this.editedIndex = this.orderData.items.indexOf(item);
            this.productDialog = true;
            this.editedItem = Object.assign({}, item);
            this.isLoadingGuest = true;
            this.$http
                .get(`/api/Product/${item.productId}`, {
                    params: {
                        restaurantId: this.selectedRestaurant,
                        startDate: this.startDate,
                        endDate: this.endDate,
                    },
                })
                .then((response) => {
                    this.product = response.data;
                    this.product.productLocalizations =
                        response.data.productLocalizations.reverse();
                    if (this.product.images.length) {
                        const randomParam = Math.random();
                        this.productImage =
                            process.env.VUE_APP_IMAGE_API_HOST +
                            this.product.images[0].imagePath +
                            `?random=${randomParam}`;
                    } else {
                        this.productImage = null;
                    }

                    this.isLoadingGuest = false;
                })
                .catch((error) => {
                    alert(error);
                });
        },
        closeProductDialog() {
            this.productDialog = false;
            this.productImage = "";
            this.product = {};
        },
        calculateBarWidth(value, topObject) {
            // Находим максимальное значение из totalAmount и totalAmountOld
            const maxTotalAmount = Math.max(
                ...topObject
                    .filter((product) => product !== null)
                    .map((product) =>
                        Math.max(product.totalAmount, product.totalAmountOld)
                    )
            );
            if (value == 0) {
                return 1;
            } else {
                // Рассчитываем процент от максимального значения
                return maxTotalAmount > 0 ? (value / maxTotalAmount) * 90 : 0;
            }
        },
    },

    watch: {
        columnFilters: {
            handler: "getProductsList",
            deep: true, // Нужно для отслеживания вложенных изменений
        },
        downLoadOrderData(newData) {
            if (newData && newData.items && newData.items.length > 0) {
                console.log("Data updated and ready for Excel:", newData.items);
            }
        },

        search: debounce(function () {
            this.currentPage = 1;
            // Выполняем запрос с задержкой после изменения переменной поиска
            this.getProductsList();
        }, 500),
        options: {
            handler() {
                this.getProductsList(); // вызов API при изменении опций
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
::v-deep .v-menu__content.theme--light.menuable__content__active {
    max-height: 304px !important;
    min-width: 430px !important;
    top: 464px !important;
    left: 677px !important;
    transform-origin: left top !important;
    z-index: 8;
}

::v-deep .v-input__slot {
    margin-bottom: 0px;
}

::v-deep .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.custom-scroll-2 {
    padding-top: 13px !important;
    padding-left: 10px !important;
}

.custom-scroll-2>.v-menu__content.theme--light.menuable__content__active {
    max-height: 304px !important;
    min-width: 430px !important;
    top: 472px !important;
    left: 1442px !important;
    transform-origin: left top !important;
    z-index: 8 !important;
}

/* .custom-scroll-2>.v-select>.v-input__control>.v-input__slot {
    cursor: pointer;
    margin-top: 23px !important;
    padding-left: 10px !important;
} */

::v-deep .v-text-field>.v-input__control>.v-input__slot:after {
    background-color: none !important;
    border-color: none !important;
    border-style: none !important;
    border-width: thin 0 !important;
    transform: none !important;
}

::v-deep .v-text-field>.v-input__control>.v-input__slot:before {
    border-color: inherit;
    border-style: none;
    border-width: thin 0 0;
    border: none !important;
}

::v-deep .theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    border-color: rgba(0, 0, 0, 0.42);
    border: none !important;
}

/* ::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar {
    width: 8px;
    
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar {
    width: 8px;
    
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-thumb {
    background-color: #888;
    
    border-radius: 4px;
    
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-track {
    background-color: #f1f1f1;
   
} */
.locale-1 {
    display: flex;
    flex-direction: column;
    /* gap: 18; */
    /* max-width: 227px; */
    /* margin-bottom: 18px; */
    display: flex;
    flex-direction: column;
    /* gap: 18; */
    width: 100%;
    margin-bottom: 10px;
}

.locale-container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.info-container {
    display: flex;
    /* align-items: flex-start; */
    justify-content: space-between;
    height: 100%;
    margin-top: 10px;
    flex-direction: column;
    display: flex;
    /* align-items: flex-start; */
    justify-content: space-between;
    height: 100%;
    margin-top: 10px;
    flex-direction: column;
}

.dateContainer {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 20px; */
}

.dateTitle {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: right;
    margin-bottom: 5px;
    color: #323c4d;
}

.dateDescription {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: right;
    color: #323c4d;
}

.product-total-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    margin-top: 10px;
    color: #323c4d;
}

.product-details-items-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    color: #323c4d;
}

.product-details-items-description {
    margin-top: 5px;
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #323c4d;
}

.product-total-price {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    color: #323c4d;
    margin-top: 10px;
}

.info-price {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 0px;
    color: #383838;
}

.icons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7.5px;
}

.icons-container-1 {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
}


.right-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: right;
    /* max-height: 400px; */
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-bottom: 20px; */
    /* text-align: right; */
    /* max-height: 400px; */

    min-width: 100px;
    /* min-width: 100px; */
}

.locale-title-container {
    display: flex;
    align-items: center;
}

.locale-container {
    display: flex;
    flex: 1;

    flex-direction: column;
    gap: 30px;
}

.product-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    /* Высота строки */
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #383838;
    margin-left: 10px;

    /* max-height: calc(19.36px * 2); */
    /* Высота для двух строк */
    overflow: hidden;
    /* Скрывает лишний текст */
    text-overflow: ellipsis;
    /* Добавляет троеточие */
    display: -webkit-box;
    /* Для многострочного текста */
    /* -webkit-line-clamp: 2; */
    /* line-clamp: 2; */
    /* Ограничивает до двух строк */
    -webkit-box-orient: vertical;
    /* Устанавливает вертикальную ориентацию */
}

.product-description {
    color: #848484;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    margin-top: 5px;

    max-height: calc(16.94px * 3);
    overflow-y: auto;
    color: #848484;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 0px;
    max-height: calc(16.94px * 3);
    overflow-y: auto;
}

.localeContainer {
    background-color: #606c80;
    color: #fff;
    font-family: Inter;

    font-size: 13px;
    font-weight: 200;
    text-transform: uppercase;
    line-height: 14.52px;
    border-radius: 3px;
    padding: 3px;
    max-width: 30px;
    text-align: center;
}

.stat-item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    cursor: pointer;
}

.stat-title {
    /* flex: 1; */
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    white-space: nowrap;
    /* Запрещает перенос строки */
    overflow: hidden;
    /* Обрезает текст, который выходит за пределы контейнера */
    text-overflow: ellipsis;
    color: #323c4d;
    width: 100%;
    max-width: 340px;
}

.bar-amount {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 9.68px;
    color: #606c80;
}

.stat-bars {
    /* flex: 2; */
    margin: 0 0 0 10px;
    width: 100%;
}

.stat-bar {
    display: flex;
    align-items: center;
    margin: 3px 0;
}

.bar {
    height: 9px;
    background-color: #2c3e50;
    margin-right: 5px;
}

.bar-delivery {
    height: 9px;
    background-color: #2c3e50;
    margin-right: 5px;
}

.bar-in-house {
    height: 9px;
    background-color: #2c3e50;
    margin-right: 5px;
}

.current .bar {
    background-color: #34495e;
}

.previous .bar-in-house {
    background-color: #51818f;
}

.current .bar-in-house {
    background-color: #13414e;
}

.previous .bar-delivery {
    background-color: #006d5a;
}

.current .bar-delivery {
    background-color: #90c6bc;
}

.previous .bar {
    background-color: #95a5a6;
}

.bottom-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 7px;
}

.bottom-text-top {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 9.68px;
    margin-right: 30px;
    color: #98a2b2;
}

.percent-hg {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
}

.statcode-container {
    display: flex;
    align-items: center;
}

.red-circle {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-right: 10px;
}

.green-circle {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    margin-right: 10px;
}

/* ::v-deep .v-data-table-header th.sortable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row
}

.v-data-table-header th.sortable ::v-deep .v-icon {
  margin-left: 4px;
} */
.sortable {
    cursor: pointer;
}

.active {
    font-weight: bold;
}

.comment-adress {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #606c80;
}

.line-chart {
    position: relative;
    width: 100%;
    height: 115px;
}

.line {
    position: absolute;
    bottom: 20px;
    width: 100%;
    height: 2px;
    background-color: #374151;
}

.marker {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.dot {
    width: 8px;
    height: 8px;
    background-color: #374151;
    border-radius: 50%;
    margin-bottom: 5px;
}

.dot::after {
    content: "";
    position: absolute;
    left: 5%;
    width: 2px;
    height: 48px;
    /* Длина пунктирной линии */
    border-left: 2px dashed #374151;
    transform: translateX(-50%);
}

.flag {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #13414e;
    color: #fff;
    max-width: 100px;
    width: 100%;
    padding: 0px 15px;
    border-radius: 5px;
    text-align: center;
    margin-left: 10px;
    /* Отступ от точки */
    position: relative;
    top: 24px;
    left: 5%;
    /* Поднятие флага */
}

.flag-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
}

.flag>small {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
}

.flag::before {
    content: "";
    position: absolute;
    left: -5px;
    /* Положение треугольника */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #13414e;
    /* Цвет треугольника, совпадающий с цветом флага */
}

.flag::after {
    content: "Приложение";
    /* Текст надписи */
    position: absolute;
    top: -15px;
    /* Положение надписи над флагом */
    left: 50%;
    transform: translateX(-50%);
    /* Центрирование надписи */
    color: #606c80;
    /* Цвет текста */
    font-size: 12px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
}

.dot::before {
    content: "";
    position: absolute;
    bottom: -44px;
    /* Размещение точки в конце пунктирной линии */
    left: 5%;
    width: 8px;
    height: 8px;
    background-color: #374151;
    border-radius: 50%;
    transform: translateX(-50%);
}

/* Процентные метки */
.tick {
    position: absolute;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    bottom: -6px;
    /* Совместим точки с линией */
}

.tick-label {
    font-size: 12px;
    color: #374151;
    margin-top: 5px;
}

.tick-dot {
    width: 8px;
    height: 8px;
    background-color: #374151;
    border-radius: 50%;
}

#marker-1 {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 19, 20, 0, 0, -46, 6, 1);
}

#marker-1>.flag {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 19, 20, 0, -115, 2, -2, 1);
    background: #006d5a;
    margin-left: 5px;
    top: 22px;
    left: 2%;
}

#marker-1 .dot::after {
    content: "";
    position: absolute;
    left: 4%;
    width: 2px;
    height: 96px;
    /* Длина пунктирной линии */
    border-left: 2px dashed #374151;
    transform: translateX(-50%);
}

#marker-1 .dot:before {
    content: "";
    position: absolute;
    bottom: 0px;
    /* Размещение точки в конце пунктирной линии */
    left: 5%;
    width: 0px;
    height: 0px;
    background-color: #374151;
    border-radius: 50%;
    transform: translateX(-50%);
}

#marker-1 .flag::before {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #006d5a;
    border-right: 6px solid transparent;
}

.review-bubble {
    border-radius: 50px;
    padding: 3px 15px;
    /* width: 95px; */
    height: 24px;
    background: #98a2b2;
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    color: white;
    margin-bottom: 0px;
    text-align: center;
}

.review-bubble-container {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.review-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
}

.last-container {
    max-width: 247px;
    width: 100%;
}

.order-column-divider {
    border: 1px solid #d4d4e1;
}

.header-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 17px;
}

.no-address-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 167px;
    font-size: 16px;
    color: #333;
    font-family: Inter;
    margin-bottom: 0px;
}

.loyality-container {
    overflow-y: auto;
    overscroll-behavior: none;
    padding-right: 10px;
}

.rest-logo {
    margin-right: 11px;
    border-radius: 100px;
    width: 35px;
    height: 35px;
}

.rest-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    color: #333;
    margin-bottom: 0px;
}

.rest-adress {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: #98a2b2;
    margin-bottom: 0px;
}

.restaurant-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item:first-child {
    /* padding-left: 10px; */
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* Вы можете оставить этот стиль, если он вам нужен */
    line-height: normal;
}

.restaurant-item.active {
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid rgb(152, 162, 178);
}

.block.active {
    background: rgba(212, 212, 225, 0.35);
    /* измените на нужный цвет */

    border: 1px solid var(--obvodka);
    /* например, рамка вокруг активного блока */
}

.block:not(.active):hover {
    box-shadow: 0 0 10px 0 rgba(137, 137, 137, 0.25);
    background: white;
}

.error-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: center;
}

.error-heading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: center;
}

.guest-grid .v-data-table-header th div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.cguest-grid .v-data-table-header th div small {
    font-size: 12px;
    color: gray;
}

.guest-grid th {
    white-space: nowrap;
}

.guest-grid th div {
    font-weight: bold;
}

.guest-grid th small {
    display: block;
    font-size: 12px;
    color: grey;
}

.blocks-img {
    width: 1.35416666667vw;
    height: 2.68518518519vh;
}

.block-date {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.78125vw;
    color: #606c80;
    margin-bottom: 0px;
}

.neutral-arrows {
    color: #98a2b2;
}

.percent-text-gray {
    font-family: Inter;
    font-weight: 400;
    font-size: 0.520vw;
    color: #323c4d;
}

.arrow-color-bot {
    color: red;
    /* width: 16px;
      height: 13px; */
    /* padding-top: 2px; */
}

.arrow-color-top {
    color: green;
    /* width: 16px;
      height: 13px; */
    /* padding-top: 2px; */
}

.percent-status-green {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 15px; */
    margin-top: -11px;
    line-height: 1;
    width: 37px;
}

.percent-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 15px; */
    line-height: 1;
    width: 37px;
}

.percent-text-green {
    color: #00b800;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.520vw;
    line-height: 9.68px;
}

.percent-text-red {
    color: #e41313;
    font-family: Inter;
    font-weight: 400;
    font-size: 0.520vw;
    line-height: 9.68px;
}

.block-title-number {
    font-family: Inter;
    font-weight: 700;
    font-size: 1.875vw;
    color: #323c4d;
    margin-bottom: 0px;
}

.block-numbers {
    display: flex;
    align-items: center;
}

.blocks-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.block-title {
    font-family: Inter;
    font-weight: 400;
    font-size: 1.25vw;
    color: #606c80;
    margin-bottom: 0px;
    text-wrap: nowrap;
}

.blocks-container {
    display: flex;
    /* flex-wrap: wrap; */
    gap: 22px;
    /* Отступ между блоками */
    /* justify-content: center; */
}

.block {
    /* width: 312px;
    height: 136px;
    padding: 11px 18px 8px 20px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer; */
    width: 312px;
    /* height: 136px; */
    padding: 11px 18px 8px 20px;
    background-color: white;
    /* color: white; */
    /* display: flex; */
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
}


::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 12px !important;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .theme--light.v-sheet {
    background-color: transparent;
    border-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
}

::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 36px;
    box-shadow: none;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

::v-deep .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    border-bottom: 0.5px solid rgb(212, 212, 225);
}

::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
}

.calendar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

::v-deep .v-input__prepend-inner {
    margin-top: 8px !important;
}

::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    background: #fff;
}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

::v-deep .v-text-field .v-label--active {
    max-width: 1000%;
    top: 5px !important;
    left: -60px !important;
    font-size: 0.9vw;
    font-family: Inter;
    font-weight: 400;
    line-height: 15.73px;
    color: #98a2b2 !important;
}

.error-container {
    max-width: 50%;
    /* Задаем максимальную ширину контейнера */
    margin: 0 auto;
    margin-top: 20px;
    /* margin: 0px 20px; */
    padding: 20px;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-exel {
    margin-left: 10px;
}

.btn-exel {
    border-radius: 5px;
    padding: 5px 18px;
    color: white;
    background: #323c4d;
    height: 36px;
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.btn-exel-callendar {
    border-radius: 5px !important;
    padding: 5px 18px !important;
    color: white !important;
    background: #323c4d !important;
    height: 36px !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    display: flex !important;
    align-items: center !important;
    text-transform: capitalize !important;
}

.custom-text-field ::v-deep .v-label {
    transform: translateY(-10px);
    /* Смещение текста вверх */
    font-size: 29px;
    /* Размер шрифта */
    color: #6b6b6b;
    /* Цвет текста */
}

.custom-text-field {
    background-color: white;
    margin-bottom: 100px;
    /* Белый фон */
}

.date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 12px;
    min-width: 260px;
}

.buttons-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

#date-picker-body {
    background: #ffffff;
}

.title {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px !important;
    color: rgb(50, 60, 77);
    margin-right: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .title-popular-container {
    display: flex;
    align-items: center;
    justify-content: center;
} */

.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.custom-toolbar {
    height: 100%;
}

.tabs {
    padding: 0 30px 0px 0px;
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
    border-radius: 4px;
    /* Радиус скругления ползунка */
}

::v-deep .custom-scroll .v-select--is-menu-active::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет трека */
}

::v-deep .v-autocomplete__content.v-menu__content {
    color: #000;
    background-color: #000;
    background: #000;
}

::v-deep .v-select__slot {
    border: none;
}

::v-deep .v-text-field--outlined.v-input--is-focused fieldset {
    border: none;
}

::v-deep .v-autocomplete.v-input--outlined .v-input__control {
    border: none !important;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgb(224, 224, 224);
}

::v-deep .v-text-field--outlined fieldset {
    border: none !important;
}

::v-deep .v-autocomplete__content.v-menu__content .v-select-list {
    border-radius: 0 0 4px 4px;
    background: green;
}

::v-deep .v-list-item:first-child {
    border-bottom: none;
}

::v-deep .v-list .v-list-item--active {
    color: black;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* Толщина, стиль и цвет левой границы */
}

::v-deep .v-autocomplete__content input {
    border: none;
    /* Убираем рамку */
}

::v-deep .v-autocomplete__content .v-list-item__title {
    color: black;
    /* Цвет текста */
}

/* Стили для скрытия рамки при наведении */
::v-deep .v-autocomplete__content .v-list-item:hover {
    border: none;
}

/* Применяем ваши стили к полю ввода */
::v-deep .v-autocomplete__content input {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    width: 100%;
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content>.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content {
    max-height: none;
}

::v-deep .v-autocomplete__content>.v-menu__content>.v-select-list {
    color: pink !important;
    background-color: #00b800 !important;
    margin: 100px;
}

#accounts>.container {
    border-radius: 8px;
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
    color: #fff;
    /* White text color */
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
    background-color: #357a38;
    /* Darker green background on hover */
}

.role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
}

.profile-menu-item:hover {
    /* Здесь вы можете задать стили для hover-эффекта */
    background-color: #f0f0f0;
    /* Пример: изменение цвета фона при наведении */
    color: #333;
    /* Пример: изменение цвета текста при наведении */
    cursor: pointer;
    /* Пример: изменение курсора при наведении */
    /* Другие стили по вашему усмотрению */
}

.border-input {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
    padding: 6px;
}

.border-white {
    border-radius: 8px;
    border: 1px solid var(--02, white);
    background: var(--white, #fff);
    padding: 6px;
    cursor: pointer;
    width: 100%;
}

.divider-modal {
    background: #e0e0e0;
    height: 1px;
    width: 100%;
    /* Расширяем на всю ширину родительского контейнера */
}

.divider-modal-order {
    background: #e0e0e0;
    height: 1px;
    width: 100%;
    margin-top: 13px;
    /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
}

.input-menu {
    position: absolute;
    bottom: 32%;
    left: 30.7%;
    width: 347px;
    height: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
    transition: bottom 0.3s;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 100;
    /* right: -15px; */
}

.select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    /* margin-left: 15px; */
}

.option-btn {
    margin-bottom: 0px;
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

/* .btn-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 46px;
      align-items: center;
      margin-right: 20px;
    } */
.red-dot-margin-2 {
    margin-left: -17px;
}

.red-dot-margin-1 {
    text-align: center;
    margin-left: -17px;
}

.btn-contaiener {
    display: flex;
    justify-content: space-evenly;
}

.cancel-btn {
    color: var(--02, #848484);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--02, #848484);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-right: 21px;
}

.delete-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.snackbar-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0178571429em;
    line-height: 1.25rem;
    margin-right: auto;
    padding: 14px 16px;
    text-align: initial;
    cursor: pointer;
}

.save-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #00b800);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
}

.del-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #b80900);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-left: 30px;
}

.text-limit {
    width: 30ch;
    /* Отображение примерно 10 символов */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--Gray-1, #333);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
}

.horizontal-line {
    /* Ширина div'а будет равна 100% от родительского контейнера */
    height: 1px;
    /* Толщина линии */
    background: #848484;
    /* Цвет линии (в данном случае черный) */
}

.custom-select {
    position: relative;
    width: 350px;
    cursor: pointer;
    /* margin-left: 9px; */
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
}

.select-arrow {
    position: absolute;
    top: 40%;
    right: 30px;
    /* transform: translateY(-50%); */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    /* Цвет стрелочки */
}

.col-description,
:focus {
    outline: none;
    /* Убираем внешний контур (outline) при фокусе */
    border: none;
    /* Убираем границу (border) при фокусе */
    box-shadow: none;
    /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
    color: #e41313;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.col-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    margin-bottom: 0px;
}

.col-paddings-r-l {
    padding-right: 0px;
    padding-left: 0px;
}

.col-content {
    display: flex;
    align-items: flex-start;
}

.role-margin {
    margin-right: 37px;
}

.role-margin-1 {
    margin-right: 30px;
}

.col-content-2 {
    display: flex;
    align-items: center;
    width: fit-content;
}

.col-description {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 146.667% */
    margin-bottom: 0px;
    word-wrap: break-word;
    resize: none;
    height: 30px;
    /* min-height: 20px; */
    width: 100%;
    /* margin-left: 18px; */
}

::v-deep .v-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: none;
}

.modal-title {
    color: var(--gray-1, #333);
    font-family: Inter !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

.page-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.vertical-line {
    width: 1px;
    height: 42px;
    background: #e0e0e0;
    margin-left: 31px;
}

.vertical-line-1 {
    width: 1px;
    height: 50px;
    background: #e0e0e0;
}

.add-btn .add-text {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 12px;
    transition: color 0.3s ease;
}

.add-btn:hover .add-text {
    /* Цвет текста при наведении */
    color: white;
    /* Замените "red" на желаемый цвет текста */
    /* font-weight: 500;
      font-size: 17px; */
}

.add-btn {
    display: flex;
    align-items: center;
    padding: 5px 18px 5px 18px;
    margin-left: 37px;
    cursor: pointer;
    border-radius: 5px;
    background: rgb(50, 60, 77);
    height: 36px;
}

.top-panel {
    /* height: 50px; */
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}

.search-container {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-pluss-btn {
    margin-left: 10px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-notfound {
    /* display: flex; */
    margin: 0 auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}

.search-component {
    max-width: 430px;
    width: 100%;
    height: 45px;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
}

.search-component__input {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    /* width: 100%; */
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.search-component__input-filter {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    width: 100%;
    outline: unset;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.search-component__icon {
    position: absolute;
    top: 14.5px;
    right: 55px;
}

#staff-add-button {
    border-radius: 8px;
    background-color: #333;
    color: var(--white, #fff);
    gap: 12px;
    padding: 10px 56px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 45px;
}

::v-deep .guest-grid tbody tr:nth-child(2n) {
    background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    color: var(--gray-1, #333) !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
    color: #606c80 !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-wrap: nowrap;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: 2px solid #606c80;
}

.progress-bar {
    width: 100%;
    height: 4px;
    background-color: #98a2b2;
    /* Серый цвет */
    /* border-radius: 10px; */
    overflow: hidden;
}

.progress-bar-fill {
    height: 100%;
    background-color: #323c4d;
    /* Красный цвет заполнения */
    width: 0;
    transition: width 0.3s ease;
}

.card-bonus {
    display: flex;
    align-items: center;
}

.bonus-points {
    font-family: Inter;
    font-size: 30.62px;
    font-weight: 700;
    /* line-height: 37.06px; */
    text-align: left;
    color: #323c4d;
    margin-bottom: 0px;
    margin-right: 5px;
}

.first-order-container {
    margin-top: 10px;
}

.register-container {
    margin-top: 14px;
    margin-bottom: 27px;
}

.birthday-container {
    margin-bottom: 21px;
}

.gender-container {
    margin-bottom: 15px;
}

.phone-container {
    margin-top: 12px;
    margin-bottom: 15px;
}

.small-icons {
    width: 20px;
    height: 20px;
}

.user-column {
    display: flex;
    flex-direction: column;
}

.avatar-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #606c80;
}

#style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #606c80;
}

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #606c80;
}

.location-containers {
    margin-bottom: 19px;
    display: flex;
    margin-top: 12px;
    flex-direction: column;
}

.location-container {
    overflow-y: auto;
    overscroll-behavior: none;
}

.location-info {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.location-adress {
    font-family: Inter;
    font-weight: 500;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
}

.location-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 7px;
}

.third-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    min-height: 631px;
}

.order-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    color: #606c80;
    margin-bottom: 0px;
}

.order-info-text-second {
    font-family: Inter;
    font-weight: 700;
    font-size: 24px;
    /* text-align: right; */
    color: #323c4d;
    margin-bottom: 0px;
}

.order-info-containers {
    display: flex;
    justify-content: space-between;
}

.order-info-containers-1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.order-info-text-green {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #00b800;
    margin-bottom: 0px;
    padding: 0 10px;
}

.order-info-container {
    margin-top: 24px;
}

.date-containers {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 14px;
}

.date-container-1 {
    max-width: 121px;
    width: 100%;
}

.date-container-2 {
    max-width: 115px;
    width: 100%;
    margin-right: 74px;
}

.date-order {
    font-family: inter;
    font-weight: 600;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 16px;
}

.flex-column-1 :last-child {
    margin-bottom: 0px;
}

.flex-column-1 {
    margin-right: 47px;
}

.flex-column-2 {
    margin-left: 27px;
}

.order-1 {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #606c80;
    margin-bottom: 0px;
}

.divider-modal {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;

    /* Расширяем на всю ширину родительского контейнера */
}

.review-img {
    width: 22px;
    padding-right: 5.8px;
}

.review-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    color: #606c80;
}

.review-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    color: #606c80;
    margin-bottom: 0px;
    padding-right: 10px;
}

.title-name {
    font-family: Inter;
    font-weight: 500;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 0px;
    padding-left: 14px;
}

.dialogImage {
    width: 319px;
    height: 319px;
    border-radius: 5px;
    object-fit: contain;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-card {
    padding: 10px 26px 38px;
    background-color: #f8f8f8 !important;
}

.card-username {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    /* line-height: 19.36px; */
    text-align: center;
    margin-bottom: 0px;
    margin-top: 8px;
}

.card-item-second-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    color: #323c4d;
}

.card-item-second {
    background: #fff;
    max-width: 392px;
    width: 100%;
    border-radius: 5px;
    /* max-height: 631px; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    padding: 15px 13px;
}

.card-item {
    background: #fff;
    /* max-width: 247px; */
    width: 247px;
    border-radius: 5px;
    /* height: 620px; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
}

.card-item-3 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 13px 21px 15px;
    border-radius: 5px;
}

.card-item-5 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    /* height: 35.2777777778vh; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 5px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.payment-content {
    margin-top: 14px;
}

.payment-content-total {
    margin-top: 14px;
}

.payment-text {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 15.51px;
    color: #383838;
}

.payment-amount {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.51px;
    color: #383838;
}

.payment-text-total {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 17.45px;
    color: #383838;
}

.payment-amount-total {
    font-family: Inter;
    font-size: 15px;
    font-weight: 700;
    line-height: 17.45px;
    color: #383838;
}

.payment-block p {
    margin-bottom: 10px;
}

.adress-info {
    margin-top: 14px;
}

.addition-info {
    /* margin-bottom: 50px; */
    margin-top: 12px;
}

.review-info {
    /* margin-bottom: 50px; */
    margin-top: 17px;
}

.card-item-4 {
    background: #fff;
    width: 100%;
    max-width: 388px;

    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 13px 15px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.card-item-6 {
    background: #fff;
    width: 100%;
    max-width: 388px;
    max-height: 360px;
    /* height: 231px; */
    display: flex;
    flex-direction: column;
    padding: 10px 13px 15px 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.card2-title {
    margin: 0;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;
    color: #323c4d;
}

.card-cashback {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #98a2b2;
}

.bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.price-circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #323c4d;
}

.price-circle-gray {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #98a2b2;
}

.price-line-gray {
    background-color: #98a2b2;

    width: 347px;
    height: 2px;
    margin-top: -8px;
}

.price-line-fill {
    width: 260px;
    height: 5px;
    background-color: #323c4d;
    border-radius: 4px;

    margin-top: -4px;
}

.price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.price-container p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #98a2b2;
}

.price-remains {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

    color: #98a2b2;
}

.card-label {
    color: #606c80;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 2px; */
    text-align: left;
    /* margin-top: 8px; */
    margin-bottom: 5px;
}

.card-text {
    color: #333333;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
    margin-bottom: 0px;
}

.card-line {
    background: #d4d4e1;
    height: 1px;
    width: 100%;
    margin-top: 6.8px;
}

.modal-title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
}

.modal-header {
    padding: 16px 5px 0px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* height: 100%; */
    gap: 20px;
    padding-top: 10px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100%;
}

::v-deep .v-dialog {
    margin: 0px;
}

.cashback-title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;

    color: #323c4d;
}

.chart-container {
    position: relative;
}

.chart-circle-container {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;

    left: -12.5px;
}

.chart-circle-gray {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    border-radius: 50%;
    background-color: #98a2b2;
}

.chart-circle-container div {
    margin-bottom: 40px;
}

.cashback-line-gray {
    width: 3px;
    height: 280px;
    background-color: #9f9f9f;
}

.cashback-line-fill {
    /* position: relative; */
    /* top: 90px; */
    margin-top: -200px;
    width: 6px;

    margin-left: -1.5px;
    border-radius: 4px;
    background-color: #323c4d;
}

.cashback-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 15px;
    width: 50%;
}

.cashback-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.cashback-info div {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    margin-left: 12.5px;
}

.cashback-info h4 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    margin-left: 5px;
}

.cashback-info p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin: 0;
    margin-left: 5px;

    color: #606c80;
}

.btn-download {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cashback-prices p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    /* line-height: 0px; */
    text-align: left;
    margin-bottom: 72.5px;

    /* margin: 0; */
    color: #606c80;
}

.order-item-count {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    min-width: 30px;
    margin-bottom: 0px;
}

.order-item-name {
    font-family: Inter;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    text-align: left;
    max-width: 250px;
    margin-bottom: 0px;
    color: #323c4d;
    text-wrap: balance;
}

.order-item-price {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: right;
    width: 100%;
    margin-bottom: 0px;
    color: #323c4d;
}

.order-item-container {
    margin-top: 15px;
}

.order-price-container {
    /* width: 25%; */
    margin-right: 5px;
}

.order-item-addition {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
}

.doughnut-border {
    background-color: white;
    padding: 0px 18px 19px;
    border-radius: 5px;
    /* max-width: 478px; */
    width: 100%;
}

.doughnut-border-mini-variant {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 478px;
    width: 100%;
}

.doughnut-name {
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    color: #323c4d;
    margin-bottom: 19px;
    margin-top: 9px;
}

.average-check-block {
    background-color: white;
    padding: 18px;
    border-radius: 5px;
    max-width: 273px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.block-container {
    display: grid;
    gap: 20px 50px;
    grid-auto-flow: row;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
}

.toolbar-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

}

.restaurant-input {
    padding: 12px !important;
    /*  width: 100%;  */

    min-width: 260px !important;

}


.block-content {
    /* display: flex;
    flex-direction: row; */
}

.block-text-header {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18.15px;
    text-align: left;
}

.block-text-amount {
    font-family: Inter;
    font-size: 1.25vw;
    font-weight: 700;
    line-height: 29.05px;
    text-align: left;
    color: #006d5a;
    margin-bottom: 0px;
}

.average-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.average-row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 0px;
}

.line-chart-block {
    background-color: white;
    padding: 0 18px;
    border-radius: 5px;
    max-width: 469px;
    width: 100%;
}

.bar-chart-block {
    background-color: white;
    padding: 0px 18px;
    border-radius: 5px;
    /* max-width: 377px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 207px;
}

.name-square-application {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #13414e;
}

.name-square-all {
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #006d5a;
}

.bars-container {
    display: flex;
    align-items: center;
}

.bar-name {
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    color: #323c4d;
    margin-bottom: 0px;
}

.line-chart-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 15px;
}

.line-chart-overlay {
    position: relative;
    overflow: hidden;
}

.line-chart-overlay .line-chart,
.line-chart-overlay .line-chart-legend {
    filter: blur(10px);
    /* Применяет размытие */
    pointer-events: none;
    /* Блокирует клики на размытой области */
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    background: rgba(255, 255, 255, 0.8);
    /* Белый полупрозрачный фон */
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

#custom-toolbar ::v-deep .v-toolbar__content {
    padding-left: 0 !important;

    /* Убирает отступ слева */
}


@media screen and (max-width:950px) {

    /* .buttons-container {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  margin-bottom: 10px;
} */

    .restaurant-input {
        padding: 12px 0 12px 0 !important;
        width: 100%;
    }

    .date-picker {
        padding: 12px 0 12px 0 !important;
    }

    .toolbar-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }

    .title {
        font-size: 18px !important;
        justify-content: flex-start;
        align-items: flex-start;
    }

    ::v-deep .v-label--active {
        font-size: 16px !important;
        left: 0px !important;
        margin-left: 25px;
        line-height: 15.73px;
        color: #98a2b2 !important;
    }

    .toolbar-container {
        flex-direction: column;
        height: 100%;
    }

    #custom-toolbar ::v-deep .v-toolbar__content {
        height: 100% !important;
    }
}

@media screen and (max-width:700px) {

    .error-container {
        max-width: 100%;


    }

    .bar-chart-block {
        width: 100%;
        max-width: 100%;
    }

    .title-container {
        display: block;
    }

    .card-item-second {
        background: #fff;
        max-width: 100%;
        width: 100%;
        /* max-height: 631px; */
        /* height: 100%; */
    }

    /* .search-toolbar{

        
    } */

    .card-item-3 {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;


    }

    .search-component__input {

        width: 90%;
        padding: 0 40px 0 15px;
        font-size: 14px;
        line-height: 22px;
    }

    .search-component__input-filter {
        width: 90%;
        font-size: 12px;
        line-height: 22px;
    }

    .third-container {
        min-height: auto;
        max-width: 100%;
    }

    .dialogImage {
        /* display: flex;
        margin: 0 auto; */
        width: 100%;
    }


    .modal-container {
        flex-direction: column;
        justify-content: center;
    }

    .table-container {
        padding-top: 30px;
    }

    ::v-deep .v-data-table__wrapper {
        padding-top: 40px;
    }

    .search-component {
        margin-top: 30px;
    }

    ::v-deep .search-toolbar .search-toolbar.v-toolbar__content {
        height: 100% !important;
    }

    .search-buttons-container {
        flex-direction: column;
    }

    .stat-title {
        font-size: 10px;

        line-height: 18.15px;
        white-space: nowrap;
        /* Запрещает перенос строки */
        overflow: hidden;
        /* Обрезает текст, который выходит за пределы контейнера */
        text-overflow: ellipsis;
        color: #323c4d;
        width: 100%;
        max-width: 150px;
    }

    .percent-text-green {
        font-size: 8px;
    }

    .percent-text-red {
        font-size: 8px;
    }

    .percent-text-gray {
        font-size: 8px;
    }

    .date-picker {
        padding: 10px 0 10px 0;
    }

    .restaurant-input {
        padding: 5px 0 12px 0 !important;
        min-width: 240px !important;
    }

    ::v-deep .v-label--active {
        font-size: 16px !important;
        left: 0px !important;
        margin-left: 25px;
        line-height: 15.73px;
        color: #98a2b2 !important;
    }

    #custom-toolbar ::v-deep .v-toolbar__content {
        height: 100% !important;
    }

    .btn-exel {
        font-size: 12px;
        display: flex;
        align-items: center;
    }


    .title {
        font-size: 18px !important;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .block-container {
        grid-template-columns: 1fr;

    }

    .toolbar-container {
        flex-direction: column;
        height: 100%;
    }

    .v-data-footer__select {
        width: 200px;
    }

    .toolbar-buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
    }
}


@media screen and (max-width:1000px) {
    .stat-title {
        max-width: 150px;
        width: 100%;

    }

    .doughnut-border {
        width: 100%;
        max-width: 100%;
    }

    .block-container {
        gap: 15px;
    }

    .stat-title {
        font-size: 12px;
    }

    .bar-chart-block {
        width: 100%;
        max-width: 100%;
    }
}

@media screen and (max-width:450px) {
    .buttons-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        margin-bottom: 10px;
    }

}

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}

@media (max-width: 1600px) {
    .block {
        width: calc(50% - 11px);
        /* 50% ширины с учётом gap */
    }
}

@media (max-width: 1146px) {
    .block {
        width: calc(19.5% - 11px);
        /* 50% ширины с учётом gap */
    }

    .block-title {
        font-size: 1.1vw;
    }
}

@media (max-width: 768px) {
    .block {
        width: 100%;
        /* Полная ширина на мобильных устройствах */
    }
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar {
    width: 8px;
    /* Ширина скроллбара */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Цвет ползунка */
}

::v-deep .v-menu__content.v-select__content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Цвет фона скроллбара */
}

::v-deep .v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
    color: pink !important;
    background-color: #00b800 !important;
}

::v-deep .v-autocomplete__content {
    background-color: black;
    /* Устанавливаем черный цвет для фона меню */
    color: white;
    /* Устанавливаем белый цвет для текста в меню */
}
</style>
